// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"RZ_1b4kbN52C4hmYHuGjd"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

Sentry.init({
  debug: false,

  // Adjust this value in production, or use tracesSampler for greater control
  dsn: 'https://05b7ce3efb83cdfe9f0d892f35478fa6@o4504044807258112.ingest.sentry.io/4506038780231680',

  environment: process.env.NEXT_PUBLIC_BUILD_ENV,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  ignoreErrors: [/Load failed/i, /Failed to fetch/i],

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [],
  tracePropagationTargets: [
    'localhost',
    process.env.NEXT_PUBLIC_SITE_URL || '',
    process.env.NEXT_PUBLIC_API_URL || '',
  ],
  tracesSampleRate: process.env.NEXT_PUBLIC_BUILD_ENV == 'staging' ? 0.1 : 0,
  allowUrls: [/topgolf\.com/, /staging\.topgolf\.com/, /s3\.topgolf\.com/, /localhost/],
});
